import $ from 'jquery'
import { get } from 'lodash'
import { AddToCartError } from '../errors'

/**
 *  Lic Manager
 *  - knows config (assets, PDF)
 *  - writes/processes properties (variant id, quanity)
 *  - creates lic
 *  - updates lic
 *  - creates/updates preview
 */
export default class LicManager {
  constructor(optionsManager, fpdManager) {
    this.optionsManager = optionsManager
    this.fpdManager = fpdManager
    this.currentLic = null
    this.lineItemCacheWorker = null
    this._licCreateUrl = null
  }

  get licCreateUrl() {
    const defaultLicCreateUrl = `${this.apiUrl}/api/fe/line_item_cache.json?shop=${this.shopDomain}`
    if(this._licCreateUrl == null) {
      this.licCreateUrl = defaultLicCreateUrl
    }
    return this._licCreateUrl.toString()
  }

  set licCreateUrl(newUrl) {
    this._licCreateUrl = new URL(newUrl)
  }

  get shopDomain() {
    return this.optionsManager.shopifyDomain
  }

  get fpdAdminUrl() {
    return this.optionsManager.fpdAdminUrl
  }

  get apiUrl() {
    return this.optionsManager.apiUrl
  }

  getLicUpdateUrl(existingLic) {
    return `${this.apiUrl}/api/fe/line_item_cache/${existingLic.hash}.json?shop=${this.shopDomain}`
  }

  /**
   * TODO:
   * extract lic creator or lic model with args as class variables
   */
  processLicCreation(shopifyProduct, variant, forceCustomProduct) {
    return this.createPreviewImage().then((_createdImage) => {
      let licData = this.prepareLicData(shopifyProduct, variant.id, forceCustomProduct)
      if (get(licData, 'data.product') === false) {
        throw new AddToCartError("Could not create line item cache - no order data.")
      }
      return this.createLineItemCache(licData)
    })
  }

  processLicUpdate(existingLic) {
    return this.createPreviewImage().then((_createdImage) => {
      // let licData = this.prepareLicData(shopifyProduct, variant.id, false)

      return this.updateLineItemCache(existingLic)
    })
  }

  /**
   * Creates a line item cache on the rails server.
   *
   * @param {Object} [licData] Data for the lic, defaults to prepareLicData
   *                           method
   * @return {Promise<Object>} Promise for response object.
   */
  createLineItemCache(licData) {
    return new Promise((resolve, reject) => {
      $.ajax({
        method: "POST",
        dataType: "json",
        contentType: "application/json",
        url: this.licCreateUrl,
        data: JSON.stringify({line_item_cache: licData}),
        crossDomain: true
      }).fail((error) => {
        console.error("Could not process order", error)
        reject(error)
      }).then((response) => {
        this.currentLic = response
        resolve(response)
      })
    })
  }

  updateLineItemCache(existingLic) {
    let licData = {
      data: this.getOrderData(),
      print_data: this.getPrintOrderData(),
      image: this.previewImageData,
      title: 'test',
      shopify_domain: this.shopDomain,
    }

    return fetch(this.getLicUpdateUrl(existingLic), {
      method: "PATCH",
      body: JSON.stringify({line_item_cache: licData}),
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(response => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json()
    }).catch(error => {
      console.error(error)
      throw error
    })
  }

  /**
   * Create the lic data based on the current fpd instance.
   *
   * Uses current preview image data.
   *
   * @return {Object} Lic Data from fpd instance
   */
  prepareLicData(shopifyProduct, variantId, forceCustomProduct) {
    return {
      data: this.getOrderData(),
      print_data: this.getPrintOrderData(),
      image: this.previewImageData,
      product_id: shopifyProduct.id,
      variant_id: variantId,
      title: shopifyProduct.title,
      price: this.getCalculatedPrice(),
      weight: this.getProductWeight(),
      shopify_domain: this.shopDomain,
      keep_product: forceCustomProduct,
      new_product_name: this.getNewProductName(shopifyProduct, variantId),
      extra_tags: this.getExtraTags(shopifyProduct, variantId)
    }
  }

  getOrderData() {
    let orderData = this.fpdManager.instance.getOrder({customizationRequired: false})
    orderData = this.cleanupOrderData(orderData)
    return orderData
  }

  cleanupOrderData(orderData) {
    if(!orderData && !orderData.product) {
      return
    }
    orderData.product = orderData.product.map((product) => {
      product.options.layouts = []
      return product
    })
    return orderData
  }

  getPrintOrderData() {
    return this.fpdManager.instance.getPrintOrderData()
  }

  /**
   * Create the preview image based on the current fpd instance.
   *
   * @return {Promise<String>} Promise for base64 image data
   */
  createPreviewImage() {
    return new Promise((resolve, reject) => {
      const previewImageCreated = (imageData) => {
        this.previewImageData = imageData
        resolve(imageData)
      }

      if(this.fpdManager.instance.mainOptions.modalMode) {
        const modalWrapper = document.querySelector('.fpd-modal-product-designer')
        const modalClosed = ! modalWrapper.classList.contains('fpd-show')
        if(modalClosed) {
          modalWrapper.classList.add('fpd-show')
        }

        this.fpdManager.instance.selectView(0)
        this.fpdManager.instance.currentViewInstance.fabricCanvas.resetZoom()

        if(modalClosed) {
          modalWrapper.classList.remove('fpd-show')
        }
      }

      const previewImageOptions = this.getPreviewImageOptions()
      const previewImageViewRange = this.getPreviewImageViewRange()

      this.fpdManager.instance.getProductDataURL(
        previewImageCreated,
        previewImageOptions,
        previewImageViewRange
      )
    })
  }

  getPreviewImageOptions() {
    let stageDim = Math.max(
      this.fpdManager.instance.currentViewInstance.options.stageWidth,
      this.fpdManager.instance.currentViewInstance.options.stageHeight
    )

    return {
      format: 'png',
      multiplier: this.shopify_scale_factor || Math.min(500 / stageDim, 1),
      backgroundColor: 'transparent'
    }
  }

  getPreviewImageViewRange() {
    let lastView = this.optionsManager.getPreviewLastView()
    lastView = lastView == "max" ? this.fpdManager.instance.viewInstances.length : parseInt(lastView)
    return [0, lastView]
  }

  getCalculatedPrice() {
    return this.fpdManager.instance.calculatePrice() + (window.FPD?.additionalPrice ? FPD.additionalPrice : 0)
  }
  
  getProductWeight() {
    return this.fpdManager.instance._current_product_weight || FPD._current_product_weight
  }

  getNewProductName(shopifyProduct, variantId) {
    if(this.optionsManager.hasCallback('newProductName')) {
      return this.optionsManager.invokeCallback('newProductName', shopifyProduct, variantId)
    }
    return null
  }

  getExtraTags(shopifyProduct, variantId) {
    if(this.optionsManager.hasCallback('extraTags')) {
      return this.optionsManager.invokeCallback('extraTags', shopifyProduct, variantId)
    }
    return null
  }
}
